import React from 'react'

export default function Footer() {
  return (
    <div>
      <footer>
        <div className='footer-content'>
          <div>© 2023 Copyright: Zahid Allaulddin</div>
        </div>
      </footer>
    </div>
  )
}
